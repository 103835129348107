<template>
  <div id="mian" class="clear">
    <!-- 上面表单框 -->
    <div class="con_from">
      <div class="lineP">
        <div class="item">
          <i>分账商户</i>
          <el-input
            v-model="query.keyword"
            maxlength="128"
            suffix-icon="el-icon-search"
            placeholder="分账商户名称/编号"
          ></el-input>
        </div>
        <div class="item">
          <i>所属商户</i>
          <el-select
            v-model="query.mchId"
            placeholder="全部"
          >
            <el-option label="全部" value=""></el-option>
            <el-option
              v-for="v in mchDrop"
              :key="v.mchId"
              :label="v.mchName"
              :value="v.mchId"
            >
            </el-option>
          </el-select>
        </div>
        <div class="item">
          <i>分账商户状态</i>
          <el-select v-model="query.mchStatus">
            <el-option label="全部" value=""></el-option>
            <el-option label="未激活" value="NOTACTIVATE"></el-option>
            <el-option label="已激活" value="ACTIVATE"></el-option>
            <el-option label="已冻结" value="FROZEN"></el-option>
            <el-option label="已关闭" value="CLOSED"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item">
          <i>认证状态</i>
          <el-select
            v-model="query.authStatus"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="未认证" value="UNAUTHORIZED"></el-option>
            <el-option label="认证中" value="AUTHENTICATING"></el-option>
            <el-option label="认证成功" value="AUTH_SUCCESSED"></el-option>
            <el-option label="认证失败" value="AUTH_FAILED"></el-option>
          </el-select>
        </div>
        <div class="item">
          <i>签约状态</i>
          <el-select
            v-model="query.signStatus"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="未签约" value="NOT_SIGNED"></el-option>
            <el-option label="签约成功" value="SIGNED"></el-option>
          </el-select>
        </div>
        <div class="item">
          <i>图片审核状态</i>
          <el-select
            v-model="query.approveStatus"
            placeholder="所有"
          >
            <el-option label="全部" value=""></el-option>
            <el-option label="未上传" value="NOT_UPLOAD"></el-option>
            <el-option label="未审批" value="NOT_APPROVED"></el-option>
            <el-option label="审批通过" value="PASSED"></el-option>
            <el-option label="审批未通过" value="NOT_PASSED"></el-option>
          </el-select>
        </div>
      </div>
      <div class="lineP">
        <div class="item itemTime">
          <i>创建时间</i>
          <el-date-picker
            v-model="query.startTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
          <span style="padding-left: 10px; padding-right: 10px">-</span>
          <el-date-picker
            v-model="query.endTime"
            value-format="yyyy-MM-dd HH:mm:ss"
            type="datetime"
            placeholder="选择时间"
          >
          </el-date-picker>
        </div>
        <div class="itembtn">
          <el-button class="searchBt" @click="getLists">查询</el-button>
          <!-- <el-button @click="addsShow=true" class="searchBt">批量新增</el-button> 暂时取消-->
          <el-button
            v-auth="'ACCOUNT:ALLOC:MERCHNATS/ADD'"
            @click="addclickHander"
            class="outputBt"
            >新增</el-button
          >       
        </div>
      </div>
    </div>
    <div class="tab1">
      <table class="tab">
        <tr class="tab_title">
          <td>
            <p>创建时间</p>
          </td>
          <td>
            <p>分账商户名称</p>
          </td>
          <td>
            <p>分账商户编号</p>
          </td>
          <td>
            <p>所属商户</p>
          </td>
          <td>
            <p>分账商户状态</p>
          </td>
          <td>
            <p>认证状态</p>
          </td>
          <td>
            <p>签约状态</p>
          </td>
          <td>
            <p>图片审核状态</p>
          </td>
          <td style="width: 60px">
            <p>操作</p>
          </td>
        </tr>
        <tr v-for="(v, i) in tabData" :key="i">
          <td>
            <p>{{ v.createTime }}</p>
          </td>
          <td>
            <p>{{ v.allocMchName }}</p>
          </td>
          <td>
            <p>{{ v.allocMchId }}</p>
          </td>
          <td>
            <p>{{ v.mchName }}</p>
          </td>
          <td>
            <p>{{ mchStatus[v.mchStatus] }}</p>
          </td>
          <td>
            <p>{{ authStatus[v.authStatus] }}</p>
          </td>
          <td>
            <p>{{ signStatus[v.signStatus] }}</p>
          </td>
          <td>
            <p>{{ approveStatus[v.approveStatus] }}</p>
          </td>
          <td style="width: 60px">
            <p>
              <a
                v-auth="'ACCOUNT:ALLOC:MERCHNATS/DETAIL'"
                href="javascript:;"
                @click="detailTab(v.allocMchId)"
                class="lianjie"
                >详情</a
              >
            </p>
          </td>
        </tr>
      </table>
    </div>
    <el-pagination
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="query.pageNo"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="totalNum"
    >
    </el-pagination>
    <el-dialog title="批量新增" :visible.sync="addsShow" width="490px">
      <ul class="overAccount_ul">
        <li class="ul_left">
          <div class="overAccount_ul_title">选择文件:</div>
          <el-upload action="https://jsonplaceholder.typicode.com/posts/">
            <el-button class="addBt" style="width: 120px">选取文件</el-button>
          </el-upload>
          <a href="javascript:;" class="downBt">模板下载</a>
        </li>
      </ul>
      <span slot="footer" class="dialog-footer">
        <el-button class="exitBt" @click="addsShow = false">取 消</el-button>
        <el-button class="addBt" @click="addsShow = false">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { allocmerchantsLists } from "@/api/user/subaccount.js";
import { merchantsDrop } from "@/api/common.js";
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      query: {
        startTime: "",
        endTime: "",
        pageNo: 1,
        pageSize: 10,
        keyword: "",
        mchStatus: "",
        authStatus: "",
        signStatus: "",
        approveStatus: "",
        mchId: "",
      },
      totalNum: 0,
      tabData: [],
      mchDrop: [], //商户下拉
      mchStatus: {
        NOTACTIVATE: "未激活",
        ACTIVATE: "已激活",
        FROZEN: "已冻结",
        CLOSED: "已关闭",
      },
      authStatus: {
        null: "",
        UNAUTHORIZED: "未认证",
        AUTHENTICATING: "认证中",
        AUTH_FAILED: "认证失败",
        AUTH_SUCCESSED: "认证成功",
      },
      signStatus: {
        null: "",
        NOT_SIGNED: "未签约",
        SIGNED: "签约成功",
      },
      approveStatus: {
        null: "",
        NOT_UPLOAD: "未上传",
        NOT_APPROVED: "未审批",
        PASSED: "审批通过",
        NOT_PASSED: "审批未通过",
      },
      addsShow: false, //批量新增
    };
  },
  computed: {
    ...mapState({
      subaccountQueryDetail: "user_subaccountQuery",
    }),
  },
  created() {
    if (this.subaccountQueryDetail) {
      this.query = this.subaccountQueryDetail.query;
    }
    this.getDrop();

    this.getLists(false);
    this.$enter(this.$route.path, this.getLists);
  },

  methods: {
    ...mapMutations({
      setSubaccountQuery: "user_setSubaccountQuery",
      setSubaccount: "user_setSubaccount",
    }),
    // 获取下拉菜单
    getDrop() {
      merchantsDrop().then((res) => {
        if (res.resultStatus) {
          this.mchDrop = res.resultData;
        }
      });
    },
    // 获取列表详情
    getLists(reset = true) {
      if (reset) {
        this.query.pageNo = 1;
      }
      this.query.startTime = this.query.startTime ? this.query.startTime : "";
      this.query.endTime = this.query.endTime ? this.query.endTime : "";
      this.query.keyword = this.query.keyword.trim();

      allocmerchantsLists(this.query).then((res) => {
        if (res) {
          this.tabData = res.resultData.merchants;
          if (this.tabData.length == 0) {
            this.$message({
              message: "暂无数据",
              duration: 1500,
            });
          }
          this.totalNum = res.resultData.totalNum;
        }
      });
    },
    //新增按钮
    addclickHander() {
      this.setSubaccount(null);
      this.$router.push({
        name: "add_subaccountlist",
      });
    },
    // 页面大小改变
    handleSizeChange(size) {
      this.query.pageSize = size;
      this.getLists();
    },
    handleCurrentChange(page) {
      this.query.pageNo = page;
      this.getLists(false);
    },
    //详情
    detailTab(id) {
      this.$router.push({
        name: "subaccountlist_xq",
        query: {
          pageType: "edit",
          id: id,
        },
      });
    },
  },
  beforeDestroy() {
    let data = {
      query: this.query,
    };
    this.setSubaccountQuery(data);
  },
};
</script>
<style scoped>
.con_from .lineP .item{
  width: 29%;
  margin-left: 6.5%;
}
.con_from .lineP .item:first-child{
  margin-left: 0%;
}
.con_from .lineP .item i {
  width: 92px;
}
.con_from .lineP .itemTime {
    width: 50.55%;
}
.con_from .lineP .item .el-input , .con_from .lineP .item .el-select{
  width: calc(100% - 92px - 10px);
}
.con_from .lineP .itemTime .el-input{
  width: calc((100% - 92px - 24.67px - 10px) / 2);
}


.overAccount_ul {
  padding: 20px;
  overflow: hidden;
}

.overAccount_ul_title {
  height: 36px;
  font-size: 14px;
  line-height: 36px;
  color: #999999;
  text-align: left;
  margin-right: 10px;
}

.overAccount_ul .el-input,
.overAccount_ul .el-select {
  margin-bottom: 7px;
}

.overAccount_ul .downBt {
  color: #008aff;
  margin-left: 10px;
}

.overAccount_ul .ul_left {
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addBt {
  width: 60px;
  height: 32px;
  background-color: #48b8b6;
  border-color: #66b2af;
  color: #ffffff;
  font-size: 12px;
  padding: 0;
}

.exitBt {
  width: 60px;
  height: 32px;
  background-color: #ffffff;
  color: #333333;
  border-color: #d7dae2;
  font-size: 12px;
  padding: 0;
}

</style>
